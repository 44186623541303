import React, { Suspense } from "react";
import { Link } from "react-router-dom";
// const Status = React.lazy(() => import('./Status'));

class Header extends React.Component {
    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        var lastURLSegment = this.props.url;

        return (
            <div>
                <div className="header">
                    {this.props.rooms.lobby.open ? (
                        <Link
                            className={IsActive("/lobby", lastURLSegment)}
                            to="/zoo"
                        >
                            <RoomName name="Zoo" isLive={this.props.rooms.lobby.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">Zoo</div>
                    )}
                    {this.props.rooms.firststage.open ? (
                        <Link
                            className={IsActive("/firststage", lastURLSegment)}
                            to="/wallpaper"
                        >
                            <RoomName name="Wallpaper Girl" isLive={this.props.rooms.firststage.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">Wallpaper Girl</div>
                    )}
                    {this.props.rooms.secondstage.open ? (
                        <Link
                            className={IsActive("/secondstage", lastURLSegment)}
                            to="/unsaid"
                        >
                            <RoomName name="Unsaid" isLive={this.props.rooms.secondstage.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">Unsaid</div>
                    )}
                    {this.props.rooms.thirdstage.open ? (
                        <Link
                            className={IsActive("/july", lastURLSegment)}
                            to="/july"
                        >
                            <RoomName name="First" isLive={this.props.rooms.thirdstage.isLive}/>
                        </Link>
                    ) : (
                        <div className="nav-button-closed">July 15th</div>
                    )}
                    {this.props.rooms.instalation.open ? (
                        <Link
                            className={IsActive("/first", lastURLSegment)}
                            to="/first"
                        >
                            <RoomName name="Oyster in the Sun" isLive={this.props.rooms.instalation.isLive} />                            
                        </Link>
                    ) : (
                        <div className="nav-button-closed">First</div>
                    )}
                    <div className="flashmessage-container">
                        { typeof(this.props.flash) == 'string' && (
                            <div className="flashmessage">{this.props.flash}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;

function IsActive(name, url) {
    if (name === url) return "nav-button nav-button-is-active";
    else return "nav-button";
}

function RoomName(props) {
    return (
        <div>
            {props.name}
            {props.isLive ? <span className="nav-is-live"></span> : ''}
        </div>
    )
}
