import React, { Suspense } from "react";

import {
    Router as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";

import { createBrowserHistory } from "history";

// 
// App settings
//

import settings from '../settings.json'


// 
// Ui elements and pages
// 

import LandingPage from "./pages/LandingPage";
import MenuButton from "./ui/MenuButton";
import OverlayButton from "./ui/OverlayButton";
import Header from "./Header";
import Footer from "./Footer";
import Profile from "./Profile";

//
// Static pages
// 

import Livestream from "./LiveStream";
import About from "./pages/About";
import Privacy from "./pages/Privacy";

// 
// Rooms with 3d
// 

import Zoo from "./scenes/Zoo";
import Wallpaper from "./scenes/Wallpaper";
import Unsaid from "./scenes/Unsaid";
import July from "./scenes/July";
import First from "./scenes/First";

// 
// chat components
//

import ChatBar from "./chat/ChatBar"
import ChatMessages from "./chat/ChatMessages"

// 
// Utilities 
// 

import {
    uniqueNamesGenerator,
    adjectives,
    names,
} from "unique-names-generator";

// 
// Firebase configuration
// 

// const config = {
//     apiKey: settings.apiKey,
//     authDomain: settings.authDomain,
//     databaseURL: settings.databaseURL,
//     storageBucket: settings.storageBucket,
// };

// firebase.initializeApp(config);
import firebase from './utils/firebase'

const history = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            volume: 1,
            login: true,
            rooms: {
                lobby: { message: "", open: false, isLive: false },
                firststage: { message: "", open: false, isLive: true },
                secondstage: { message: "", open: false, isLive: false },
                thirdstage: { message: "", open: false, isLive: false },
                instalation: { message: "", open: false, isLive: false },
            },
            displayInstructions: false,
            flash: "",
            chat: true,
            showAbout: false,
            url: "",
        };

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    user: user,
                });

                if (user.displayName === null) {
                    const randomName = uniqueNamesGenerator({
                        dictionaries: [adjectives, names],
                        separator: " ",
                        style: "capital",
                    });
                    user.updateProfile({
                        displayName: randomName,
                        photoURL: "https://rewire.ams3.digitaloceanspaces.com/spp/ap_" + (Math.floor(Math.random() * 18) + 1)  + ".png",
                    });
                    this.handleUserUpdate();
                }

                let dbUser = firebase
                    .database()
                    .ref("users/" + firebase.auth().currentUser.uid);
                dbUser.set({
                    username: firebase.auth().currentUser.displayName,
                });
                dbUser.onDisconnect().remove();
            } else {
            }
        });

        this.rooms = firebase.database().ref("rooms/");
        this.rooms.on("value", (snapshot) => {
            this.setState({ rooms: snapshot.val() });
        });

        this.flash = firebase.database().ref("flash/");
        this.flash.on("value", (snapshot) => {
            this.setState({ flash: snapshot.val().message });
        });

        history.listen((location, action) => {
            this.setState({ url: location.location.pathname });
        });
    }

    // uiConfig = {
    //     signInFlow: "popup",
    //     credentialHelper: "none",
    //     signInOptions: [
    //         firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //         firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //     ],
    //     callbacks: {
    //         signInSuccessWithAuthResult: (result) => { },
    //     },
    // };

    handleInstructions = () => {
        this.setState({ displayInstructions: false });
    };

    handleMute = () => {
        this.state.volume === 0
            ? this.setState({ volume: 1 })
            : this.setState({ volume: 0 });
    };

    handleChatToggle = () => {
        this.state.chat === false
            ? this.setState({ chat: true })
            : this.setState({ chat: false });
    }

    enterTheApp = () => {
        if (this.state.user) {
            this.setState({ login: true });
            history.push("/zoo");
        } else {
            firebase
                .auth()
                .signInAnonymously()
                .then((user) => {
                    this.setState({
                        login: true,
                        user: user
                    });
                    history.push("/zoo");
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        }
    };

    enterTheAccesibleApp = () => {
        if (this.state.user) {
            this.setState({ login: true });
            history.push("/livestream");
        } else {
            firebase
                .auth()
                .signInAnonymously()
                .then((user) => {
                    this.setState({
                        login: true,
                    });
                    history.push("/livestream");
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        }
    };

    handleUserUpdate = () => {
        this.state.user.reload().then((updatedUser) => {
            this.setState({
                user: firebase.auth().currentUser,
            });
        });
    };

    getVolume = () => {
        return this.state;
    };

    render() {
        return (
            <Router history={history}>
                <div className="main-container">
                    <div className="header-container">
                        {this.state.user && this.state.login ? (
                            <div>
                                <Header
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    rooms={this.state.rooms}
                                    url={this.state.url}
                                    flash={this.state.flash}

                                />
                                {/* chat */}
                                {this.state.chat ?
                                    <div className="chat">
                                        <ChatMessages
                                            user={this.state.user}
                                        />
                                        <ChatBar
                                            user={this.state.user}
                                            logOut={() => {
                                                firebase.auth().signOut()
                                                this.setState({ user: null })
                                            }
                                            }
                                        />
                                    </div>
                                    : ""}
                                <Footer
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    handleChatToggle={this.handleChatToggle}
                                    rooms={this.state.rooms}
                                    chat={this.state.chat}
                                    user={this.state.user}
                                    logOut={() => {
                                        firebase.auth().signOut();
                                        this.setState({ user: null });
                                        this.setState({ login: false });
                                    }}
                                />
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>

                    <MenuButton
                        handleClick={() => this.setState({ showAbout: true })}
                    />
                    <OverlayButton
                        history={history}
                    />
                    {this.state.showAbout ? (
                        <About setParentState={this.setState.bind(this)} />
                    ) : null}

                    <Switch location={location}>
                        <Route path="/livestream">
                            <Livestream firebase={firebase} history={history} />
                        </Route>
                        <PrivateRoute
                            path="/zoo"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Zoo
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="zoo.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/wallpaper"
                            user={this.state.user}
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Wallpaper
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="wallpaper.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/unsaid"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Unsaid
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="unsaid.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/july"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <July
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="first.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/first"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <First
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="oyster.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                            />
                        </PrivateRoute>
                        <PrivateRoute
                            path="/profile"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Profile
                                firebase={firebase}
                                user={this.state.user}
                                handleUserUpdate={this.handleUserUpdate}
                                history={history}
                            />
                        </PrivateRoute>

                        <Route path="/privacy">
                            <Privacy />
                        </Route>
                        <Route path="/">
                            <LandingPage
                                handleOnClick={this.enterTheApp}
                                handleOnClickAccesible={
                                    this.enterTheAccesibleApp
                                }
                            />
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

function PrivateRoute(props) {
    return (
        <Route
            render={({ location }) =>
                props.user && props.login ? (
                    props.children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default App;
