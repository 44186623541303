import React from "react";
import ReactPlayer from "react-player";
import Close from './ui/CloseButton'

class LiveStream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: null,
        };
    }
    

    componentDidMount() {
        this.stream = this.props.firebase.database().ref("stream/" + 'livestream');
        this.listener = this.stream.on("value", (snapshot) => {
            const url = snapshot.val(); 
            this.setState({url: url})
            console.log(url)
        })

    }

    componentWillUnmount() {
        this.stream.off("value",this.listener)
      }

    handleClick() {
        this.props.history.back()
    }  

    render() {
        return (
            <div className="livestream">
                <Close handleClick={this.handleClick.bind(this)} classes="livestreamclose"/>
                <div className="livestream-title">
                Show Pony<br></br>
                by Show Pony, No Compliments
                </div>
                <div>
                    <div className="livestream-block">
                        <div className="song-title">Oyster in the Sun </div>
                        <div className="livestream-container">
                            <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="100%"
                                url={"https://player.vimeo.com/external/525538737.m3u8?s=f56b8ff032c3942723c38fe98892b5dd27ea8920"}
                                playing={false}
                                controls={true}
                            />
                        </div>
                    </div>
                    <div className="livestream-block">
                        <div className="song-title">Unsaid </div>
                        <div className="livestream-container">
                            <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="100%"
                                url={"https://player.vimeo.com/external/525489390.m3u8?s=75bb42613dba3f7b6a42c3242f17e68e5dc566a1"}
                                playing={false}
                                controls={true}
                            />
                        </div>
                    </div>
                    <div className="livestream-block">
                        <div className="song-title">Wallpaper Girl</div>
                        <div className="livestream-container">
                            <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="100%"
                                url={"https://player.vimeo.com/external/525524960.m3u8?s=fecb7962d2f35643cfd48e573bce3acf4e81217a"}
                                playing={false}
                                controls={true}
                            />
                        </div>
                    </div>
                    <div className="livestream-block">
                        <div className="song-title">Zoo</div>
                        <div className="livestream-container">
                            <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="100%"
                                url={"https://player.vimeo.com/external/525526965.m3u8?s=0302f1dbfe7eb746441a5bfc4ab0ef5247655b42"}
                                playing={false}
                                controls={true}
                            />
                        </div>
                    </div>
                    <div className="livestream-block">
                        <div className="song-title">First</div>
                        <div className="livestream-container">
                            <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="100%"
                                url={"https://player.vimeo.com/external/525529323.m3u8?s=6e5651cbf4e30da1fd625a59bba1aebeb1b0637c"}
                                playing={false}
                                controls={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LiveStream;
