import React , { Suspense } from 'react'


function Menu (props) {
      return (
        <div className="menu-button" onClick={props.handleClick}>
            {/* <img src="./images/supportcat.jpg" /> */}
            About
        </div>
      )
  }
  
  
  export default Menu