import React, { Suspense } from 'react'
import Close from '../ui/CloseButton'


class About extends React.Component {

  handleClick() {
    this.props.setParentState({ showAbout: false })
  }


  render() {
    return (
      <div className="about">
        <Close handleClick={this.handleClick.bind(this)} classes={"menu-button-close button-close-left"} />
        <div className="about-col">
          Hi,<br></br><br></br>
          <div>Welcome to ShowPony.World, an online 3D space designed and built by The Rodina. Here you’ll find my entire EP visualized by The Rodina plus music videos made by five artists Giulia Principe, Moa Holgersson, Paul Simon Heyduck, Victoria Nygren & Matthew Stanton (Hogwash Production). The music was written by me and produced by dear friend No Compliments</div>
          <div>
            Show Pony and this EP were born in July 2019 during an artist residency at Fish Factory in Iceland. There the initial sketches for the songs came into existence. Over the past year and a half producer No Compliments and I worked together on molding the sketches into fully fledged songs. Where only double bass and voice had existed before we added synthesizers, textures, and beats. We took a project that started out as a folk project and turned it into a playground for experimental pop music
          </div>
          <div>
            To celebrate the release of my EP I wanted to create a space where people could gather and connect with something visual as well as each other. Since this cannot happen in a physical space I thought a digital Show Pony world would be an incredible place to step into and experience.
          </div>
          <div>
            Enter Tereza & Vit, of The Rodina. The Rodina are an innovative design duo exploring spatial and interactive possibilities of virtual environments as a space for new thoughts and aesthetics that come forward due to the intersection of culture and technology. Together they have crafted this winding, wonderful universe where you can explore and chat.
          </div>
          <div>
            So, Welcome to ShowPony.world.
          </div>
          <div>
            We’re so glad you’re here.<br></br>
            Please grab a snack and stay for a while.<br></br>
            xoxo,<br></br><br></br>
          Show Pony<br></br><br></br>
          <div>
            P.S. If you like what you hear please consider supporting me and this project by purchasing the EP on Bandcamp.
          </div><br></br>
          <div>
          Buy the EP on <a href="https://showponysrsly.bandcamp.com/" target="_blank">Bandcamp.</a><br></br>
          Listen on <a href="https://open.spotify.com/artist/0kwlbksu7Mcr8EZQ4eM7iL?si=hNzA1K_nQNmjlNtiVcD0Jg" target="_blank">Spotify.</a><br></br>
          Follow on <a href="https://www.instagram.com/show_pony__/" target="_blank">Instagram.</a><br></br>
          Like on <a href="https://www.facebook.com/showponysrsly" target="_blank">Facebook.</a><br></br>
          </div>

          <div className="about-credits">
          Music by <a href="https://www.lauranygren.com/project/showponysrsly" target="_blank">Show Pony</a> & <a href="https://nocompliments.bandcamp.com/album/no-compliments" target="_blank">No Compliments</a><br></br>
          Environment design, concept and code: <a href="https://www.therodina.com/" target="_blank">The Rodina</a><br></br>
          <br></br>
          Video Makers:<br></br>
          <a href="https://www.junovisuals.com/" target="_blank">Giulia Principe</a> (Zoo)<br></br>
          <a href="https://hogwash.nyc/" target="_blank">Hogwash</a> (<a href="http://torinygren.rocks/" target="_blank">Victoria Nygren</a> & Matthew Stanton) (Wallpaper Girl, Oyster in the Sun)<br></br>
          <a href="https://www.instagram.com/animalofcenter/" target="_blank">Moa Holgersson</a> (First)<br></br>
          <a href="http://www.paul-heyduck.de/" target="_blank">Paul Simon Heyduck</a> (Unsaid)<br></br>
          <a href="https://www.anisaxhomaqi.com/" target="_blank">Anisa Xhiomaqi</a> (source photos Unsaid)<br></br>
          <a href="https://www.instagram.com/nina_k_scenography/" target="_blank">Nina Kay</a> (Set & Costume design Unsaid)

          <div><br></br><br></br>
          Special thanks to:<br></br><br></br>
          The Rodina, for your amazing work and collaboration. <br></br>
          Bronne Keesmaat & the Rewire Team<br></br>
          No Plexus<br></br>
          Alistair Sung, Allison Philips, Blaise Adamson, Isa Goldschmeding, Thora Sveinsdóttir,  Ramon Lormans, for your beautiful playing<br></br>
          Brandenburg Mastering<br></br>

          </div>


          </div>



          </div>


        </div>
      </div>
    )
  }
}


export default About