import React, { Suspense } from "react";
import Loading from "../ui/Loading";
import Instructions from "../ui/Instructions";

const BabylonScene = React.lazy(() => import("../BabylonScene.jsx"));

const FirstStage = function (props) {
    return (
        <Suspense fallback={<Loading />}>
            <BabylonScene
                getVolume={props.getVolume}
                firebase={props.firebase}
                sceneFile={props.sceneFile}
                roomName="NextA"
                user={props.user}
                history={props.history}
                handleInstructions={props.handleInstructions}
                streamName="firststage"
            />
            {props.displayInstructions && <Instructions />}
        </Suspense>
    );
};

export default FirstStage;
