import React, { useState, useRef, useEffect } from 'react'

import InfiniteScrollReverse from "./InfiniteScroll.jsx";

import firebase from '../utils/firebase'
import classes from "./chat.scss";

let NUMBERTOLOAD = 10;

function ChatMessages(props) {
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [userId, setUserId] = useState(props.user.uid);

    const scrollArea = useRef(null);

    let messagesRef = firebase.database().ref("messages/");

   
    //
    // Load first 10 messages and add lisener on new messages
    //

    useEffect(() => { 
        messagesRef.orderByKey().limitToLast(20).get().then((snapshot) => {
            const newMessages = [
                ...Object.entries(snapshot.val()).map(([key, value]) => ({key,...value}))
              ];

            setMessages((messages) => [...messages, ...newMessages])

            //
            // Add lissener when new message appears
            //

            messagesRef.limitToLast(1).on("child_added", (payload) => {
                setMessages((messages) => {

                    const newMessage = [{
                        ...payload.val(),
                        key: payload.key
                      }];
                    // 
                    // chesk if is not first message and if it's new message
                    // 
                    if (messages.length && newMessage[0].timestamp > messages[messages.length - 1]?.timestamp ) {
                        // 
                        // If it's new message scrolls out down..
                        // 

                        setTimeout(() => {
                            scrollArea.current.firstChild.scrollTop = 1000000;
                        }, 0);
                        return [...messages, ...newMessage] 
                    } else {
                        return [...messages]  
                    }
                })
            })


        });
        return () =>{
            messagesRef.off()
        }
    }, [])


    //   
    // Load messages when load on top
    //

    const loadMessages = (page) => {
        setLoading(true);
        messagesRef.orderByKey().endAt(messages[0].key).limitToLast(10).get().then((snapshot)=>{
            const newMessages = [
                ...Object.entries(snapshot.val()).map(([key, value]) => ({key,...value}))
              ];
              newMessages.pop()
              setMessages((messages) => [...newMessages, ...messages])  
            setLoading(false);
        });
    };

    return (
        
        <span ref={scrollArea}>
            <InfiniteScrollReverse
                className="chat-messages"
                hasMore={true}
                isLoading={loading}
                loadMore={loadMessages}
                loadArea={20}
            >
                {messages.map((message, i) => (
                    <Message
                        key={message.key}
                        message={message.message}
                        name={message.name}
                        photoURL={message.photoURL}
                        currentUserId={userId}
                        messageUserId={message.userId}
                        messageIndex={i}
                        messagesLenght={messages.length}
                    />
                ))}
            </InfiniteScrollReverse>
        </span>
    );
}

export default ChatMessages

function Message(props) {
  return (
    <div className="chat-block">
      <div>
        <div className="chat-image">
            {
                props.photoURL ? <img src={props.photoURL} /> : <img src={"https://rewire.ams3.digitaloceanspaces.com/spp/ap_" + (Math.floor(Math.random() * 18) + 1)  + ".png"} />
            }
        </div>
        <div className={(props.messageUserId === props.currentUserId) ? "chat-content chat-content-mymessage" : "chat-content"}>
          <div className="chat-name">{props.name}</div>
          <div className="chat-content-message">{props.message}</div>
        </div>
      </div>
    </div>
  )
}
