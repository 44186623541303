import React , { Suspense } from 'react'


function OverlayButton (props) {

        const handleClick = () => {
            props.history.push('livestream')
        }

      return (
        <div className="overlay-button" onClick={handleClick}>
           Video
        </div>
      )
  }
  
  
  export default OverlayButton 