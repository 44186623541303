import React from 'react';
import FileUploader from "react-firebase-file-uploader";
import 'regenerator-runtime/runtime';
import Close from "./ui/CloseButton";

class Profile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUploading: false,
            isProcessing: false,
            canSave: false,

            avatarURL: this.props.user.photoURL,
            name: this.props.user.displayName,
            
        }
    }

    // handleChangeUsername = event =>
    // this.setState({ username: event.target.value });
    handleUploadStart = () => {
        this.setState({ 
            isUploading: true, 
            canSave: false
        })
    }

    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = async (filename) => {
        this.setState({ 
            isUploading: false,
            isProcessing: true
        })

        const file = 'thumb_' + filename
        const profileImage = 'https://firebasestorage.googleapis.com/v0/b/next-2020-24e8d.appspot.com/o/' + file + '?alt=media'

        const storageRef = this.props.firebase.storage().ref().child(file);

        // this.setState({ avatarURL: profileImage, , isUploading: false })
        keepTrying(30, storageRef).then((url) =>{ 
            this.setState({ 
                avatarURL: profileImage, 
                isUploading: false,
                canSave: true,
                isProcessing: false
            })
        });
    }

    handleChange = (e) => {
        this.setState({
            name: e.target.value,
            canSave: !this.state.isUploading
        });
    }

    handleOnSave = () => {
        if (this.state.canSave) {
            this.props.user.updateProfile({
                displayName: this.state.name,
                photoURL: this.state.avatarURL,
            }).then(()=>{
                this.props.handleUserUpdate()
                this.setState({
                    canSave: false
                })
            })
        }
    }

    handleOnCancel = () => {
        console.log(this.props.history)
        this.props.history.back()
    }

    handleSubmit = (e) => {
        e.preventDefault()
    }


    render() {       
        
        let progress;
        if (this.state.isUploading || this.state.isProcessing) {
            progress = "Processing image";
        } else if (this.state.canSave) {
            progress = "You can save changes";
        }
        
        return (
            <div className="profile-container">
                {/* <div>{this.props.user && <span> {this.props.user.displayName}</span>}</div> */}

                <form onSubmit={this.handleSubmit}>
                    <label>Profile Name</label>
                    <div>
                        <input type="text" value={this.state.name} onChange={this.handleChange} />
                    </div>
                </form>

                <div className="profile-image-container">
                    <div>Profile Image</div>
                    <div className="header-avatar">
                        <img src={this.state.avatarURL} />
                    </div>
                    <div className="profile-progress">
                        {progress}
                    </div> 
                    
                    <div>
                        {/* <label className="profile-button">
                            Upload File
                            <FileUploader
                                hidden
                                accept="image/*"
                                name="avatar"
                                randomizeFilename
                                storageRef={this.props.firebase.storage().ref('')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                            />
                        </label> */}
                    </div>   
                </div>
                { this.state.canSave 
                ? <div className="profile-button profile-save" onClick={this.handleOnSave}>Save</div> 
                : <div className="profile-button profile-save profile-button-inactive">Save</div>}  
                <Close handleClick={this.handleOnCancel} classes={"menu-button-close button-close-right"} />    
            </div>
        )
    }
}

function delay(t, v) {
    return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
    });
  }
  
  function keepTrying(triesRemaining, storageRef) {
    if (triesRemaining < 0) {
      return Promise.reject('Server not responding');
    }
  
    return storageRef.getDownloadURL().then((url) => {
      return url;
    }).catch((error) => {
      switch (error.code) {
        case 'storage/object-not-found':
          return delay(1000).then(() => {
            return keepTrying(triesRemaining - 1, storageRef)
          });
        default:
          console.log(error);
          return Promise.reject(error);
      }
    })
  }


export default Profile