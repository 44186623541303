import './style/style.scss'

import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App'

import { Notifier } from '@airbrake/browser';

// 
// Airbreak server colecting errors on clients.
// 

// const airbrake = new Notifier({
//   projectId: 313998,
//   projectKey: 'dca9ae0f809268f81bbc244f45bc626c',
//   environment: 'production'
// });


// 
// Attach React app
// 
ReactDOM.render (
    <App />,
    document.getElementById('root')
)
