import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";


import settings from '../../settings.json'
// const settings = {
//     "colyseusUrl": "wss://pb-server-vjcjq.ondigitalocean.app",
//     "apiKey": "AIzaSyCmSD90yeEwUCRAGg_NafXWi1PBZdS4__M",
//     "authDomain": "sos-elephant.firebaseapp.com",
//     "databaseURL": "https://next-2020-24e8d.firebaseio.com/",
//     "storageBucket": "gs://next-2020-24e8d.appspot.com",
// }

const config = {
    apiKey: settings.apiKey,
    authDomain: settings.authDomain,
    databaseURL: settings.databaseURL,
    storageBucket: settings.storageBucket,
};
 
export default firebase.initializeApp(config) 